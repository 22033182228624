body {
  margin: 0px;
  background-color: #282c34;
}

* {
  text-transform: none;
  text-decoration: none;
}

.MuiDivider-root {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

#mainContainer {
  padding-top: 16px;
  padding-bottom: 16px;
}

@media (min-width: 600px) {
  #mainContainer {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

#mainContainer > *:first-child {
  padding: 12px;
  border-radius: 10px;
  background-color: white;
}

#selectLang {
  padding: 10px 10px 0px 10px;
}

@media print {
  #selectLang {
    display: none !important;
  }
}
